<template>
  <section>
    <Loader :loading="showLoader" />
    <div class="row">
      <div class="col-lg-12 pb-3">
        <button class="btn  btn-success float-right btn px-3 py-2" @click="downloadExcel">Download Excel</button>
        <router-link to="/edit-contact/1">
          <a class="btn mr-2 float-right btn px-3 py-2 btn-primary">Edit</a>
        </router-link>
      </div>
      

      <!-- Address, Contact, and Social Cards -->
      <div class="col-lg-4 grid-margin stretch-card" v-for="(detail, index) in detailCards" :key="index">
        <div class="card">
          <div class="table-responsive mb-0">
            <div class="card-title p-3 mb-0 border-bottom">
              {{ detail.title }}
            </div>
            <p class="p-3 mb-0 text-center">{{ detail.content }}</p>
          </div>
        </div>
      </div>

      <!-- Contacts Table -->
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0 pb-3">
            <div class="card-title p-3 mb-0 border-bottom">Contact Us Form</div>
            <table class="table table-centered table-nowrap border-bottom">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th  style="min-width: 140px;">Mobile No</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th style="min-width: 170px;">Type</th>
                  <th style="min-width: 200px;">Message</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(contact, index) in contacts" :key="contact.id">
                  <td>{{ index + 1 + (currentPage - 1) * pageSize }}</td>
                  <td>{{ contact.name }}</td>
                  <td>{{ contact.contryCode }} {{ contact.phone }}</td>
                  <td>{{ contact.email }}</td>
                  <td>{{ formatDate(contact.created_at) }}</td>
                  <td>{{ contact.type }}</td>
                  <td>{{ contact.message }}</td>

                  <td><a style="cursor: pointer;" v-on:click="deleteContacts(contact._id)" class="text-primary">
                      <i class="mdi mdi-window-close font-size-18"></i>
                    </a></td>
                </tr>
              </tbody>
            </table>

            <!-- Pagination Controls -->
            <div class="d-flex justify-content-center p-3">
              <button class="btn btn-size btn-outline-secondary mx-1" :disabled="currentPage === 1"
                @click="changePage(currentPage - 1)">
                Previous
              </button>

              <button v-for="page in pages" :key="page"
                :class="['btn btn-size', 'mx-1', page === currentPage ? 'btn-primary' : 'btn-outline-secondary']"
                @click="changePage(page)">
                {{ page }}
              </button>

              <button class="btn btn-size btn-outline-secondary mx-1" :disabled="currentPage === totalPages"
                @click="changePage(currentPage + 1)">
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>



<script>
import Loader from "../components/Loader";
import { contactApi, sliderApi } from "../api";
import axios from 'axios';
const BASE_URL = process.env.VUE_APP_BASE_URL;
export default {
  name: "contact",
  components: {
    Loader,
  },
  data() {
    return {
      contacts: [],
      contactDetails: {},
      currentPage: 1,
      totalPages: 1,
      pageSize: 150, // Number of contacts per page
      showLoader: false,
    };
  },
  computed: {
    detailCards() {
      return [
        {
          title: "Address",
          content: this.contactDetails.address || "N/A",
        },
        {
          title: "Contact",
          content: `${this.contactDetails.email || "N/A"}, ${this.contactDetails.phone || "N/A"
            }`,
        },
        { title: "Social", content: "LinkedIn, Instagram, YouTube, Facebook, Twitter" },
      ];
    },
    pages() {
      // Generate an array of page numbers [1, 2, 3, ..., totalPages]
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    },
  },
  mounted() {
    this.fetchContacts(this.currentPage);
    this.ContactDetailsById();
  },
  methods: {
    async downloadExcel() {
      try {
        // Send a GET request to the server to download the Excel file
        const response = await axios.get(`${BASE_URL}/downloadExcel
        `, {
          responseType: 'blob', // Important for handling binary data
        });

        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'contacts.xlsx'; // Set the file name

        // Append the link to the body
        document.body.appendChild(link);
        // Trigger a click on the link to download the file
        link.click();
        // Clean up and remove the link
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading Excel file:', error);
        alert('Failed to download the Excel file.');
      }
    },

    formatDate(date) {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(d.getDate()).padStart(2, '0');
      return `${day}/${month}/${year}`; // Desired format: dd/MM/yyyy
    },
    async ContactDetailsById() {
      this.showLoader = true;
      const { data } = await sliderApi.getContactDetailsById("1");
      this.contactDetails = data.data.data;
      this.showLoader = false;
    },
    async fetchContacts(page) {
      this.showLoader = true;
      const { data } = await contactApi.contact(page);
      this.contacts = data.data.data.contacts;
      this.totalPages = Math.ceil(data.data.data.totalContacts / this.pageSize);
      this.showLoader = false;
    },
    changePage(page) {
      this.currentPage = page;
      this.fetchContacts(page);
    },
    deleteContacts(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.showLoader = true;
          const response = contactApi.delectContactById(id).then((response) => {
            this.showLoader = false;
            if (response.data.code == 200) {
              this.fetchContacts(this.currentPage);
              if (result.value) {
                this.$swal(
                  "Deleted!",
                  "Your file has been deleted.",
                  "success"
                );
              }
            }
          });
        }
      });
    },
  },
};

</script>

<style>
.btn-size {
  padding: .59rem .9rem;
  font-size: 11px !important;
  border: 1px solid #dc3545;
  border-radius: 0px;
}

.table td {
  white-space: normal;
  line-height: 1.4;
  letter-spacing: .2px;
}
</style>
